import { Auth0UserProfile } from 'auth0-js';
import Rollbar from 'rollbar';

export class RollbarService {
    public rollbar: Rollbar;

    public configure(accessToken: string, environment: string, version: string): void {
        this.rollbar = new Rollbar({
            accessToken,
            captureUncaught: true,
            captureUnhandledRejections: true
        });

        this.rollbar.configure({
            accessToken,
            payload: {
                environment,
                version,
                person: {
                    id: 'no person configured',
                    username: 'no person configured'
                }
            }
        });
    }

    public configurePerson(profile: Auth0UserProfile): void {
        if (this.rollbar)
            this.rollbar.configure({
                payload: {
                    person: {
                        id: profile.sub,
                        username: profile.nickname
                    }
                }
            });
    }
}
