import { AureliaConfiguration } from 'aurelia-configuration';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Auth0DecodedHash, Auth0UserProfile, AuthOptions, WebAuth } from 'auth0-js';
import { EventEmitter } from 'events';
import jwtDecode from 'jwt-decode';
import { RollbarService } from 'services/rollbar-service';
import { LocalStorageHelper, LocalStorageKeys } from 'utilities/local-storage-helper';
import moment from 'moment';

declare var ga: any;

@autoinject
export class AuthenticationProvider {

    public profile: Auth0UserProfile;
    public authNotifier: EventEmitter = new EventEmitter();
    private auth: WebAuth;
    private options: AuthOptions;
    private token: string;

    constructor(
        private router: Router,
        private config: AureliaConfiguration,
        private rollbarService: RollbarService
    ) {
        this.options = {
            audience: 'https://cyber.api.marlink.io',
            clientID: this.config.get('credentials.auth0ClientId'),
            domain: 'marlink.eu.auth0.com',
            redirectUri: this.config.get('credentials.callbackUrl'),
            responseType: 'token id_token',
            scope: 'openid profile email app_metadata'
        };

        this.auth = new WebAuth(this.options);

        this.initializeProfile();
    }

    /**
     * Inspects the localStorage for a token and validates it.
     */
    public isAuthenticated(): boolean {
        // check whether the current time is past the access token's expiry time
        const expiresAt = LocalStorageHelper.get<number>(LocalStorageKeys.ExpiresAt);
        return new Date().getTime() < expiresAt;
    }

    /**
     * Redirects user to login lock.
     */
    public login(): void {
        this.auth.authorize(this.options);
    }

    /**
     * Clears locally stored token and profile data
     * and redirects to 'login'.
     */
    public logout(): void {
        // Remove auth-related settings from localStorage
        LocalStorageHelper.remove(LocalStorageKeys.AccessToken);
        LocalStorageHelper.remove(LocalStorageKeys.ExpiresAt);
        LocalStorageHelper.remove(LocalStorageKeys.IdToken);
        LocalStorageHelper.remove(LocalStorageKeys.Companies);
        LocalStorageHelper.set(LocalStorageKeys.LastSignOff, moment.utc());

        // Note: Don't clear company, because it'll revert to 'biz1000' or .first() - and we won't be able to switch.
        // LocalStorageHelper.remove(LocalStorageKeys.Company);
        // LocalStorageHelper.remove(LocalStorageKeys.CompanyName);

        this.authNotifier.emit('authChange', false);
        this.token = null;

        this.router.navigate('login');
        this.reload();
    }

    public async handleAuthentication(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.auth.parseHash((error, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.setSession(authResult);
                    resolve();
                } else if (error)
                    reject(error);
            });
        });
    }

    public reload(): void {
        // Important: forceReload(true) causes the page to always be reloaded from the server.
        // If it is false or not specified, the browser may reload the page from its cache.
        window.location.reload();
    }

    private setSession(authResult: Auth0DecodedHash): void {
        // set the time that the access token will expire at
        const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
        LocalStorageHelper.set<string>(LocalStorageKeys.AccessToken, authResult.accessToken);
        LocalStorageHelper.set<string>(LocalStorageKeys.IdToken, authResult.idToken);
        LocalStorageHelper.set<number>(LocalStorageKeys.ExpiresAt, expiresAt);
        this.profile = authResult.idTokenPayload;

        if (this.config.get('rollbar.enabled'))
            this.rollbarService.configurePerson(this.profile);
    }

    private initializeProfile(): void {
        const idToken = LocalStorageHelper.get<string>(LocalStorageKeys.IdToken);
        if (idToken) {
            this.profile = jwtDecode(idToken);

            if (this.config.get('rollbar.enabled'))
                this.rollbarService.configurePerson(this.profile);
        }
    }
}
