import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject} from 'aurelia-framework';
import {EventKeys} from 'enums/event-keys';
import moment from 'moment';
import {LocalStorageHelper} from 'utilities/local-storage-helper';
import {CompanyLightweight} from './cyber-api';

declare var ga: any;

@autoinject()
export class StateApi {

    constructor(
        private ea: EventAggregator,
    ) {
    }

    public company(): string {
        let companyState = this.getState(StateKeys.Company);

        // If there is no companyState, set it to the default
        if (!companyState) {
            const defaultCompany = 'biz1000';
            this.setState(StateKeys.Company, defaultCompany);
            companyState = this.getState(StateKeys.Company);
        }

        return companyState.value;
    }

    public companyName(): string {
        const companyNameState = this.getState(StateKeys.CompanyName);

        if (companyNameState) return companyNameState.value;
    }

    public companies(): CompanyLightweight[] {
        const companiesState = this.getState(StateKeys.Companies);

        if (companiesState) return companiesState.value;
    }

    /**
     * Clears and invalidates the entire state.
     */
    public clear(): void {
        for (const key in StateKeys)
            if (StateKeys.hasOwnProperty(key))
                LocalStorageHelper.remove(StateKeys[key]);
    }

    public getState(key: StateKeys): IState {
        return LocalStorageHelper.get<any>(key);
    }

    public setState(key: StateKeys, value: any): void {
        const state: IState = {
            // Defines the expiry date of the state, specified to be at 4 a.m. the next day
            expires_at: moment().startOf('day').add(1, 'day').add(4, 'hours').valueOf(),
            value
        };

        LocalStorageHelper.set<IState>(key, state);

        // Some state changes should publish an event letting the rest of the application know a state item has changed
        switch (key) {
            case StateKeys.Companies:
                this.ea.publish(EventKeys.onCompaniesChanged);
                break;
            case StateKeys.Company:
                this.ea.publish(EventKeys.onCompanyChanged);
                break;
            case StateKeys.CompanyName:
                this.ea.publish(EventKeys.onCompanyNameChanged);
                break;
            case StateKeys.VesselStats:
            default:
                break;
        }
    }
}

export enum StateKeys {
    Company = 'company',
    CompanyName = 'company_name',
    Companies = 'companies',
    VesselStats = 'vessel_stats'
}

export interface IState {
    expires_at: number;
    value: any;
}
