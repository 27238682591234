export enum ThemeColors {
    LightOrange = '#ffd770',
    Orange = '#ee7203',
    Red = '#e94d28',
    Blue = '#0066aa',
    DarkBlue = '#001c39',
    Yellow = '#fc0',
    LighterGrey = '#f0f0f0',
    LightGrey = '#e2e2e2',
    Grey = '#b2b2b2',
    DarkerGrey = '#878787',
    DarkGrey = '#5a5a5a',
    VeryDarkGrey = '#4d4d50',
    Green = '#28a745'
}
